import React, { useEffect, useState } from 'react';
import axios from 'axios';


export default function Words() {
    const [products, setProducts] = useState([]);
    const [sorted, setSorted] = useState(false);
    const [visibility, setVisibility] = useState({}); // State to track visibility for each word
    const [feedback, setFeedback] = useState({}); // State to track feedback for each word


    // Toggle visibility of individual word translation and sentence buttons
    const toggleVisibility = (id) => {
        setVisibility((prevState) => ({
            ...prevState,
            [id]: !prevState[id] // Toggle the visibility for the specific word
        }));
    };


    // GET AND SORT DATA FROM DATABASE
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch("https://sunsetbay.art/db_for_testing/german/getdata.php");
                const data = await response.json();

                // Sort the products by 'created_at' date from newest to oldest
                const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

                setProducts(sortedData);
                console.log('data from ', sortedData);
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        }
        fetchData();
    }, []);

    // Show loading screen if products array is not yet loaded from database
    if (products.length === 0) {
        return <div>Loading...</div>;
    }

    const sortWords = () => {
        const sortedProducts = [...products].sort((a, b) =>
            a.german_word.localeCompare(b.german_word)
        );
        setProducts(sortedProducts);
        setSorted(true);
    };

    const shuffleWords = () => {
        const shuffledProducts = [...products].sort(() => Math.random() - 0.5);
        setProducts(shuffledProducts);
        setSorted(false);
    };

    // Sort words by score from worst to best
    const sortByScore = () => {
        const sortedByScore = [...products].sort((a, b) => {
            // Extract successes and attempts from the score format "successes/total_attempts"
            const [successesA, attemptsA] = a.score.split('/').map(Number);
            const [successesB, attemptsB] = b.score.split('/').map(Number);
            
            // Calculate success rates (successes / total attempts)
            const successRateA = successesA / attemptsA;
            const successRateB = successesB / attemptsB;
            
            // Sort by success rates in ascending order
            return successRateA - successRateB;
        });
        
        setProducts(sortedByScore);
        setSorted(false);
    };
    


    // Handle feedback (Good/Bad) and reveal the sentence
    const handleFeedback = (id, type) => {
        setFeedback((prevState) => ({
            ...prevState,
            [id]: true // Mark that feedback was given for the specific word
        }));

        axios
            .post('https://dejazzy.net/php_db/update_score.php', { id, type })
            .then((response) => {
                setProducts((prevProducts) =>
                    prevProducts.map((product) =>
                        product.id === id
                            ? { ...product, score: response.data.score } // Update score for the matching id
                            : product // Leave other products unchanged
                    )
                );
            })
            .catch((error) => {
                console.error('Error updating score:', error);
            });
    };

    const tableStyle = {
        width: '80%',
        margin: '20px auto',
        borderCollapse: 'collapse',
        textAlign: 'left'
    };

    const thStyle = {
        borderBottom: '2px solid #ccc',
        padding: '10px',
        backgroundColor: 'green',
        color: 'white'
    };

    const tdStyle = {
        borderBottom: '1px solid #ddd',
        padding: '10px'
    };

    return (
        <div className='Words'>
            <button onClick={sortWords} disabled={sorted} style={{ margin: '20px', padding: '10px' }}>
                Sort Alphabetically
            </button>
            <button onClick={shuffleWords} style={{ margin: '20px', padding: '10px' }}>
                Sort Randomly
            </button>
            <button onClick={sortByScore} style={{ margin: '20px', padding: '10px' }}>
                Sort by Score
            </button>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thStyle}>Word</th>
                        <th style={thStyle}>Translation</th>
                        <th style={thStyle}>Sentence</th>
                        <th style={thStyle}>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(item => (
                        <tr key={item.id}>
                            <td style={tdStyle}><b>{item.german_word}</b></td>
                            <td style={tdStyle}>
                                {visibility[item.id] ? (
                                    item.english_translation
                                ) : (
                                    <button onClick={() => toggleVisibility(item.id)}>Show</button>
                                )}
                            </td>
                            <td style={tdStyle}>
                                {visibility[item.id] && !feedback[item.id] ? (
                                    <>
                                        <button onClick={() => handleFeedback(item.id, 'good')} style={{ marginRight: '10px' }}>Good</button>
                                        <button onClick={() => handleFeedback(item.id, 'bad')}>Bad</button>
                                    </>
                                ) : (
                                    feedback[item.id] ? item.sentence : ''
                                )}
                            </td>
                            <td style={tdStyle}><b>{item.score}</b></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
