import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import for redirecting

function Input() {
    const [germanWord, setGermanWord] = useState('');
    const [englishTranslation, setEnglishTranslation] = useState('');
    const [sentence, setSentence] = useState('');
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const navigate = useNavigate(); // Hook for navigation

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://sunsetbay.art/db_for_testing/german/input.php', {
                german_word: germanWord,
                english_translation: englishTranslation,
                sentence: sentence
            });
            setShowModal(true); // Show modal on success
            setTimeout(() => {
                setShowModal(false); // Hide modal after 2 seconds
                navigate('/'); // Redirect to main page
            }, 3000);
            console.log(response);
        } catch (error) {
            console.error('There was an error!', error);
            alert('Failed to add word.');
        }
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
        fontFamily: 'Arial, sans-serif'
    };

    const labelStyle = {
        marginBottom: '10px',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#333'
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '14px'
    };

    const buttonStyle = {
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: 'green',
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s'
    };

    const buttonHoverStyle = {
        backgroundColor: 'darkgreen'
    };

    // Modal styles
    const modalStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000
    };

    const modalText = {
        padding: '15vh 15vw',
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
        textAlign: 'center',
        fontSize: '25px',
        color: 'black'
    };

    return (
        <div className='Input'>
            <form onSubmit={handleSubmit} style={formStyle}>
                <label style={labelStyle}>
                    German Word:
                    <input
                        type="text"
                        value={germanWord}
                        onChange={(e) => setGermanWord(e.target.value)}
                        required
                        style={inputStyle}
                    />
                </label>
                <label style={labelStyle}>
                    English Translation:
                    <input
                        type="text"
                        value={englishTranslation}
                        onChange={(e) => setEnglishTranslation(e.target.value)}
                        required
                        style={inputStyle}
                    />
                </label>
                <label style={labelStyle}>
                    Sentence:
                    <input
                        type="text"
                        value={sentence}
                        onChange={(e) => setSentence(e.target.value)}
                        style={inputStyle}
                    />
                </label>
                <button
                    type="submit"
                    style={buttonStyle}
                    onMouseOver={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
                    onMouseOut={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor}
                >
                    Add Word
                </button>
            </form>

            {/* Modal */}
            {showModal && (
                <div style={modalStyle}>
                    <div style={modalText}>
                        Word added successfully!
                    </div>
                </div>
            )}
        </div>
    );
}

export default Input;
