import React, { useEffect, useState } from 'react';


export default function Stats() {

  const [products, setProducts] = useState([]);
  const [last7DaysCount, setLast7DaysCount] = useState(0);
  const [last30DaysCount, setLast30DaysCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);


  useEffect(() => {
    async function fetchData() {
      try {
        // const response = await fetch("https://sunsetbay.art/db_for_testing/german/getdata.php");
        const response = await fetch(process.env.REACT_APP_GET_WORDS);
        const data = await response.json();

        setProducts(data);

        // Calculate words added in the last 7 days
        // Find date 7 dayes ago 
        const now = new Date();
        const sevenDaysAgo = new Date(now.setDate(now.getDate() - 7));
        // Filter and count words added in the last 7 days
        const recentWordsCount = data.filter((word) => {
          const wordDate = new Date(word.created_at);
          return wordDate >= sevenDaysAgo;
        }).length;
        setLast7DaysCount(recentWordsCount); // Update the count state


        // Calculate words added in the last 30 days
        // Find date 30 dayes ago 
        const now30 = new Date();
        const thirtyDaysAgo = new Date(now30.setDate(now30.getDate() - 30));
        // Filter and count words added in the last 30 days
        const thirtyWordsCount = data.filter((word) => {
          const wordDate = new Date(word.created_at);
          return wordDate >= thirtyDaysAgo;
        }).length;
        setLast30DaysCount(thirtyWordsCount); // Update the count state

        // Calculate words added today
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to start of the day

        const wordsAddedTodayCount = data.filter((word) => {
          const wordDate = new Date(word.created_at);
          return wordDate >= today; // Check if word date is today
        }).length;

        setTodayCount(wordsAddedTodayCount); // Update the today count

      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    }
    fetchData();
  }, []);

  console.log(products)


  return (
    <div>
      <p>Total number of  words is : <b> {products.length} </b>  </p>
      <p>Number of words added in the last 7 days: <b> {last7DaysCount} </b> </p>
      <p>Number of words added in the last 30 days: <b> {last30DaysCount} </b> </p>
      <p>Number of words added today: {todayCount}</p> {/* Display count of today's words */}
    </div>
  )
}
