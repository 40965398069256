import React, { useState } from 'react';
import axios from 'axios';

const Edit = () => {
  const [score, setScore] = useState('0/0'); // default value

  const handleClick = (type) => {
    axios
      .post('https://dejazzy.net/php_db/score.php', { type })
      
      .then((response) => {
        setScore(response.data.scored); // update score with response
      })
      .catch((error) => {
        console.error('Error updating score:', error);
      });
  };

  return (
    <div>
      <h1>Score: {score}</h1>
      <button onClick={() => handleClick('good')}>Good</button>
      <button onClick={() => handleClick('bad')}>Bad</button>
    </div>
  );
};

export default Edit;
