import React from 'react'

export default function Download() {
  return (
    <div>
        <h1>Download JSON file to your computer</h1>
        <div><button>Download</button></div>
    </div>
  )
}
