import React, { useEffect, useState } from 'react';


function Test() {
    const [products, setProducts] = useState([]);
        // Initialize state to keep track of which word's details are visible
        const [visibleDetails, setVisibleDetails] = useState({});


        // GET AND SORT DATA FROM DATABASE 
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch("https://sunsetbay.art/db_for_testing/german/getdata.php");
                const data = await response.json();

                // Sort the products by 'created_at' date from newest to oldest
                const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

                setProducts(sortedData);
                console.log('data from ')
                console.log(sortedData);
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        }
        fetchData();
    }, []);



    // Function to toggle the visibility of the details for a specific word
    const toggleDetails = (index) => {
        setVisibleDetails((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const tableStyle = {
        width: '80%',
        margin: '20px auto',
        borderCollapse: 'collapse',
        textAlign: 'left'
    };

    const thStyle = {
        borderBottom: '2px solid #ccc',
        padding: '10px',
        backgroundColor: 'green',
        color: 'white'
    };

    const tdStyle = {
        borderBottom: '1px solid #ddd',
        padding: '10px'
    };

    return (
        <div>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thStyle}>Word</th>
                        <th style={thStyle}>Translation</th>
                        <th style={thStyle}>Sentence</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((wordObj, index) => (
                        <React.Fragment key={index}>
                            <tr onClick={() => toggleDetails(index)}>
                                <td>{wordObj.german_word}</td>
                                {visibleDetails[index] && (
                                    <>
                                        <td>
                                            {wordObj.english_translation}
                                        </td>
                                        <td>
                                            {wordObj.sentence}
                                        </td>
                                    </>
                                )}
                            </tr>

                        </React.Fragment>
                    ))}
                </tbody>
            </table>

        </div>
    );
};

// Example usage
const words = [
    { word: 'Hello', translation: 'Hola', sentence: 'Hello, how are you?' },
    { word: 'World', translation: 'Mundo', sentence: 'The world is big.' },
    // Add more words here
];







export default Test;
