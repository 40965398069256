import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './page/Home';
import Input from './page/Input';
import Navbar from './components/Navbar';
import Words from './page/Words';
import Download from './page/Download';
import Stats from './page/Stats';
import Edit from './page/Edit';
import Test from './page/Test';


function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Words />} />
            {/* <Route path="/words" element={<Words />} /> */}
            <Route path="/input" element={<Input />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/edit" element={<Edit/>} />
            <Route path="/download" element={<Download />} />
            <Route path="/test" element={<Test />} />


          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
